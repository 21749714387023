import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Post({ data }) {
  const { markdownRemark, allMarkdownRemark } = data
  const { edges } = allMarkdownRemark

  return (
    <Layout>
      <Seo
        title={markdownRemark.frontmatter.title}
        article
        lang={"pt"}
        image={markdownRemark.frontmatter.cover}
        description={"Veja mais sobre " + markdownRemark.frontmatter.title}
      />
      <section className="post-template-header">
        <img
          src={markdownRemark.frontmatter.cover}
          className="post-template-header-cover"
          alt={markdownRemark.frontmatter.title}
        />
      </section>

      <div className="container pt-2">
        {markdownRemark.frontmatter.url_equivalent && (
          <h5 className="alert alert-primary">
            Este post está disponível em inglês clicando{" "}
            <a
              rel="noopener noreferrer"
              href={markdownRemark.frontmatter.url_equivalent}
            >
              aqui
            </a>
          </h5>
        )}
        <h1>{markdownRemark.frontmatter.title}</h1>
        <h4>Postado em {markdownRemark.frontmatter.date}</h4>
        <hr />
        <div dangerouslySetInnerHTML={{ __html: markdownRemark.html }} />
        <span className="text-muted">
          Autor: {markdownRemark.frontmatter.autor}
        </span>

        <div className="row">
          {edges.map((node, i) => (
            <Link
              to={node.node.fields.slug}
              className="text-decoration-none d-block col-sm-12 col-md-6 col-lg-4 my-3"
              key={i}
            >
              <div className="post-card">
                <div className="card border-0 post-card-radius">
                  <img
                    src={node.node.frontmatter.cover}
                    className="card-img-top post-card-image-radius"
                    alt={node.node.frontmatter.title}
                  />

                  <div className="card-body">
                    <h4 className="text-center card-title">
                      {node.node.frontmatter.title}
                    </h4>
                    <p className="text-center card-text">
                      {node.node.frontmatter.date}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    allMarkdownRemark(
      filter: {
        fields: { slug: { ne: $slug } }
        fileAbsolutePath: { regex: "/(markdown/posts/pt)/" }
      }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            cover
            title
            date(formatString: "DD [de]  MMMM [de] YYYY", locale: "pt")
            autor
          }
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        cover
        url_equivalent
        title
        date(formatString: "DD [de]  MMMM [de] YYYY", locale: "pt")
        autor
      }
    }
  }
`
